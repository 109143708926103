<template>
  <div class="myCreditScore">
    <div class="container">
      <!-- 头部 -->
      <header>我的信誉分</header>

      <!-- banner -->
      <div class="banner">
        <img class="banner_img" src="@image/creditBanner.png" alt="" />

        <!-- 头像 -->
        <div class="avatar">
          <span class="user_img">
            <img
              :src="
                userInfo.avatar
                  ? userInfo.avatar
                  : require('@image/default.png')
              "
              alt=""
            />
          </span>
          <span class="user_name">{{ userInfo.nickname }}</span>
        </div>

        <!-- 信誉记录条数 -->
        <div class="record">
          信誉记录<span>{{ count_total }}条</span>
        </div>

        <!-- 信誉分 -->
        <div class="score">
          <p>信誉分</p>
          <p>{{ userInfo.total_profit }}</p>
          <p>{{ userInfo.point_grade }}</p>
        </div>
      </div>

      <!-- 主要内容 -->
      <div class="content">
        <el-tabs v-model="activeName" type="card">
          <!-- 信誉记录列表 -->
          <el-tab-pane label="信誉记录" name="first">
            <div class="filter">
              <!-- 按时间段筛选  -->
              <el-date-picker
                v-model="pickerDateRange"
                type="daterange"
                value-format="yyyy-MM-dd"
                range-separator="至"
                start-placeholder="开始"
                end-placeholder="结束"
                :clearable="false"
                :editable="false"
                @change="upRange"
                :append-to-body="false"
                :picker-options="pickerOptions"
              >
              </el-date-picker>
              <i class="el-icon-caret-bottom iconDateRange"></i>
            </div>

            <!-- 有内容 -->
            <div class="box" v-if="recordList.length">
              <div class="scored">得分: {{ scored }}分</div>
              <ul class="record_list">
                <li
                  @click="goUrl(item.discover_id, item.profit, item.type)"
                  v-for="item in recordList"
                  :key="item.id"
                  :style="{
                    cursor: item.profit > 0 && item.type ? 'pointer' : 'auto',
                  }"
                >
                  <div class="left">
                    <p>{{ item.title }}</p>
                    <p>{{ item.create_time }}</p>
                  </div>
                  <div class="right">{{ item.profit }}分</div>
                </li>
              </ul>

              <!-- 分页 -->
              <el-pagination
                hide-on-single-page
                :current-page.sync="page"
                @current-change="handleCurrentChange"
                :page-size="per_page"
                layout="prev, pager, next, jumper"
                :total="total"
              >
              </el-pagination>
            </div>

            <!-- 无内容 -->
            <div class="null-box" v-else>
              <img src="@image/qsy.png" alt="" />
              <div class="title">暂时数据</div>
            </div>
          </el-tab-pane>
          <!-- 信誉分规则 -->
          <el-tab-pane label="信誉分规则" name="second">
            <div class="rules" v-html="rules"></div>
          </el-tab-pane>
        </el-tabs>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapActions } from "vuex";

export default {
  data() {
    return {
      activeName: "first",
      count_total: 0, // 信誉记录条数
      scored: 0, // 得分
      rules: "", // 规则
      recordList: [], // 信誉记录列表
      pickerDateRange: [], // 当前选择时间段
      day: new Date().getDate(), // 日
      month: new Date().getMonth() + 1, //月
      year: new Date().getFullYear(), //年
      start_time: "", // 筛选开始时间
      end_time: "", // 筛选结束时间
      page: 1, // 页码
      per_page: 10, //  每页容量
      total: 10, // 总条数
      // 选择时间不能超过当日的日期
      pickerOptions: {
        disabledDate(time) {
          return time.getTime() > Date.now();
        },
      },
    };
  },

  mounted() {
    this.getCurrentRecord();
    this.getRules();
    this.getUserInfo();
  },

  computed: {
    ...mapState("userManagement", ["userInfo"]),
  },

  methods: {
    ...mapActions("userManagement", ["getUserInfo"]),

    // 默认获取当日收益
    getCurrentRecord() {
      let { year, month, day } = this;
      let prev_day = day - 1;
      this.start_time =
        year + "-" + this.fillingZero(month) + "-" + this.fillingZero(prev_day);
      this.end_time =
        year + "-" + this.fillingZero(month) + "-" + this.fillingZero(day);
      this.pickerDateRange = [this.start_time, this.end_time];
      this.getPointLists();
    },

    //  获取信誉记录
    async getPointLists() {
      let { code, data, message } = await this.$personalApi.getPointLists({
        start_time: this.start_time,
        end_time: this.end_time,
        page: this.page,
      });
      if (code == 200) {
        this.scored = data.month_profit;
        this.recordList = data.lists.data;
        this.count_total = data.count_total;
        this.total = data.lists.total;
        this.per_page = data.per_page;
      } else {
        this.$message.error(message);
      }
    },

    // 按时间段选择
    upRange() {
      this.start_time = this.pickerDateRange[0];
      this.end_time = this.pickerDateRange[1];
      this.page = 1;
      this.getPointLists();
    },
    // 分页
    handleCurrentChange() {
      this.getPointLists();
    },

    // 信誉分规则
    async getRules() {
      let res = await this.$loginApi.getAgreement();
      if (res.code == 200) {
        this.rules = res.data.credit;
      } else {
        this.$message.error(res.message);
      }
    },

    // 页面跳转
    goUrl(id, profit, type) {
      if (profit > 0 && type) {
        this.$router.push("/found-details-details?id=" + id);
      }
    },

    // 补0
    fillingZero(val) {
      return val > 9 ? val : "0" + val;
    },
  },
};
</script>

<style lang="scss" scoped >
@import "@assets/css/themeColor.scss";

/deep/ main {
  background: #f2f2f2;
}
.myCreditScore {
  background: #f2f2f2;
  padding-bottom: 50px;

  .container {
    width: 1200px;
    margin: 0 auto;
    background: #eaeaea;
    margin-top: 30px;
    padding-bottom: 30px;

    header {
      font-weight: 500;
      font-size: 22px;
      color: $--themeColor;
      background: #ffffff;
      padding: 15px 20px;
      border-radius: 3px;
      position: relative;

      &:after {
        content: "";
        position: absolute;
        width: 80px;
        height: 3px;
        background: $--themeColor;
        bottom: 0;
        left: 31px;
      }
    }

    .banner {
      margin: 0 auto;
      position: relative;
      width: 95%;

      .banner_img {
        width: 100%;
      }

      .avatar {
        position: absolute;
        z-index: 10;
        left: 30px;
        top: 40px;

        .user_img {
          position: relative;
          display: inline-block;
          overflow: hidden;
          width: 103px;
          height: 103px;
          border-radius: 50%;

          img {
            width: 100%;
            position: absolute;
            left: 50%;
            top: 50%;
            transform: translate(-50%, -50%);
            vertical-align: middle;
          }
        }

        .user_name {
          display: inline-block;
          height: 103px;
          line-height: 103px;
          font-size: 32px;
          color: #fff;
          vertical-align: top;
          margin-left: 10px;
        }
      }

      .record {
        position: absolute;
        left: 30px;
        bottom: 40px;
        width: 183px;
        height: 38px;
        line-height: 38px;
        text-align: center;
        background: #ffffff;
        box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16);
        border-radius: 16px;
        font-size: 18px;
        color: #717171;

        span {
          margin-left: 10px;
        }
      }

      .score {
        position: absolute;
        right: 160px;
        top: 31px;
        text-align: center;
        width: 195px;
        height: 195px;
        padding-top: 35px;
        box-sizing: border-box;

        p:nth-child(1) {
          font-size: 16px;
          color: #ffffff;
          text-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16);
        }

        p:nth-child(2) {
          margin-top: 10px;
          font-size: 62px;
          font-weight: 500;
          color: #ffffff;
          text-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16);
        }
        p:nth-child(3) {
          font-size: 12px;
          font-weight: 500;
          color: #03dbc6;
          margin-top: 20px;
        }
      }
    }

    .content {
      margin: 0 auto;
      width: 95%;

      /deep/ {
        .el-tabs--card > .el-tabs__header {
          border: none;
          margin: 0;
        }
        .el-tabs--card > .el-tabs__header .el-tabs__nav {
          background: #d8fffb;
          border-radius: 0 50px 0 0;
          border: none;
        }
        .el-tabs .el-tabs__item {
          background: #fff;
          font-weight: normal;
          border: none;
          line-height: 40px;
          border-radius: 0 50px 0 0;

          &:hover {
            color: #000;
          }
        }
        .el-tabs .el-tabs__item:last-child {
          background: #d8fffb;
          padding-right: 25px;
        }
        .el-tabs .el-tabs__item.is-active {
          border-radius: 0 50px 0 0;
          font-weight: normal;
          transform: none;
          color: #000;
          border: none;
        }
        .el-tabs__content {
          background: #fff;
          padding: 20px 30px;
          min-height: 500px;
        }
      }

      .filter {
        position: relative;

        /deep/.el-date-editor--daterange {
          z-index: 1;
          height: 30px;
          box-sizing: border-box;
          border-radius: 7px;
          border: none;
          background: #eaeaea;
          width: max-content;

          .el-range-separator {
            height: 30px;
            line-height: 30px;
          }

          .el-range__close-icon {
            width: 8px;
          }

          .el-range-input {
            width: 80px;
            padding: 0 3px;
            background: #eaeaea;
          }

          .el-icon-date {
            display: none;
          }
        }

        .iconDateRange {
          position: absolute;
          color: #8f8f8f;
          left: 200px;
          top: 50%;
          transform: translateY(-50%);
          z-index: 999;
        }
      }

      .scored {
        margin-top: 15px;
        color: #b1b1b1;
        font-size: 14px;
        border-bottom: 1px solid #c1c1c1;
        padding: 10px 5px;
      }

      .record_list {
        li {
          display: flex;
          justify-content: space-between;
          align-items: center;
          color: #717171;
          font-size: 14px;
          border-bottom: 1px solid #c1c1c1;
          padding: 10px 5px;

          .left {
            p:first-child {
              font-size: 16px;
              margin-bottom: 10px;
            }
            p:last-child {
              font-size: 12px;
              color: #c1c1c1;
            }
          }

          .right {
            color: #333333;
          }
        }
      }

      .el-pagination {
        text-align: center;
        margin-top: 20px;
      }

      .rules {
        padding: 10px 5px;
        line-height: 25px;
      }
    }
  }
}
</style>